.header-container {
	text-align: center;
	background-color: var(--default-background-color-header);
	color: hsl(0, 0%, 100%);
	width: 100%;
	z-index: 1;
}

.header-container-inner {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	margin: 0 auto 0 auto;
	height: 3.25rem;
}

.header-container-inner-minimize {
	height: 0;
	opacity: 0;
	transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.header-container-inner-expand {
	height: 3.25rem;
	opacity: 1;
	transition: height 0.3s 0s ease-in, opacity 0.3s ease-in-out;
}

@media screen and (min-width: 600px) {
	.header-container-inner {
		width: 600px;
	}
}

.header-title-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 2.5rem;
	overflow: hidden;
}

.header-title {
	margin: 0 0.5rem 0 1rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.header-buttons-container {
	display: flex;
	flex-direction: row;
}

.copy-feedback-container {
	position: fixed;
	width: 100%;
	z-index: 4;
	bottom: 4rem;
}

.copy-feedback {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 80%;
	margin: 1rem auto;
	padding: 0.5rem;
	background-color: white;
	border-radius: 12px;
	box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 600px) {
	.copy-feedback {
		width: 550px;
	}
}

.copy-feedback-text {
	color: #003366;
	font-size: 14px;
	margin: auto 1rem;
}

.close-feedback-window {
	height: 1.5rem;
	padding: 0;
	border: none;
	outline: none;
	background-color: white;
	margin: auto 1rem;
}

.close-feedback-window img {
	width: 1.5rem;
}
