.invisible-overlay {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}

.menu-container {
  display: grid;
  max-height: 87vh;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 12px;
  width: auto;
  position: absolute;
  right: 0;
  top: 2.5rem;
  z-index: 4;
}

@media screen and (min-width: 600px) {
  .menu-container {
    right: calc((100vw - 600px) / 2);
  }
}

.main-page-container {
  right: 0;
  grid-row: 1;
  grid-column: 1;
}

.list-options-container {
  width: 0;
  grid-row: 1;
  grid-column: 1;
}

.menu-header {
  max-width: 90%;
  margin: 1.1rem 0 0.5rem 1rem;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  word-wrap: break-word;
}

.menu-element-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "CoopGroteskRegular";
  text-align: left;
}

.menu-element-title {
  max-width: 8rem;
  margin: 0;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
}

.menu-element-title-active {
  font-family: "CoopGroteskBold";
  font-weight: 100;
  color: #44affc;
}

.menu-element-subtitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #003366;
}

.horizontal-line {
  height: 1px;
  background: #cccccc;
  border: none;
  margin: 0 1rem;
}

.more-options-button:hover {
  cursor: pointer;
}

.menu-new-list-icon {
  width: 1.3rem;
  margin: 0 1rem 0 0;
}

.menu-option-icon {
  width: 1.3rem;
  margin: 0 1rem 0 1rem;
}

.menu-option-button {
  display: flex;
  align-items: center;
}

.menu-option-button:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
  outline: none;
}
