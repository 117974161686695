.delete-button {
  font-family: "CoopGroteskBold";
  font-weight: 100;
  font-size: 16px;
  color: white;
  background-color: #d62215;
  border: 1px solid #d62215;
  width: 7rem;
  height: 2.5rem;
  padding: 0;
  border-radius: 100px;
}

.delete-button:focus {
  box-shadow: 0 0 0 2px #003366;
  outline: none;
}

.delete-button:hover {
  background-color: #e67a73;
  border: 1px solid #e67a73;
  cursor: pointer;
}
