.category-container {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.category-container-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.category-item-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 1.5rem;
  background-color: #003366;
  color: white;
  border-radius: 12px;
  font-size: 13px;
}

.category-info-text {
  font-family: "CoopGroteskRegular";
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #003366;
  list-style-type: none;
  margin: 1rem 0 0 0;
}

@media screen and (min-width: 600px) {
  .category-container {
    width: 560px;
    margin: 0 auto;
  }
  .category-container-header {
    width: 560px;
    margin: 0 auto;
  }
}

.category-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.category-title {
  color: #003366;
  font-size: 17px;
  width: 100%;
  margin: 0;
}

.checked-category-title {
  font-family: "CoopGroteskRegular";
  color: #003366;
  font-size: 17px;
  width: 100%;
  margin: 0;
}

.category-content {
  width: 100%;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
}
