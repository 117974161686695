:root {
  --coop-gray: #efefe9;
  --coop-dark-blue: #003366;
  --coop-light-blue: #dcf0fa;
  --default-background-color-header: var(--coop-dark-blue);
  --default-background-color-body: var(--coop-light-blue);
  --fade-background-color: #00000070;
  --focus-color-web: #e67a73;
  --focus-color-mobile: #0040cc;
  --focus-color: var(--focus-color-web);
}

@font-face {
  font-family: "CoopGroteskBoldCondensed";
  src: url(fonts/WOFF2/CoopGroteskBoldCondensed.woff2) format("woff"),
  url(fonts/WOFF/CoopGroteskBoldCondensed.woff);
}

@font-face {
  font-family: "CoopGroteskBold";
  src: url(fonts/WOFF2/CoopGroteskBold.woff2) format("woff"),
  url(fonts/WOFF/CoopGroteskBold.woff);
}

@font-face {
  font-family: "CoopGroteskItalic";
  src: url(fonts/WOFF2/CoopGroteskItalic.woff2) format("woff"),
  url(fonts/WOFF/CoopGroteskItalic.woff);
}

@font-face {
  font-family: "CoopGroteskRegular";
  src: url(fonts/WOFF2/CoopGroteskRegular.woff2) format("woff"),
  url(fonts/WOFF/CoopGroteskRegular.woff);
}

body {
  margin: 0;
  font-family: "CoopGroteskRegular", "CoopGroteskItalic", "CoopGroteskBold",
    "CoopGroteskBoldCondensed";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--default-background-color-body);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button:hover {
  cursor: pointer;
}

h3 {
  font-family: "CoopGroteskBold";
  font-weight: 100;
}

span {
  font-family: "CoopGroteskRegular";
}
