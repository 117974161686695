.header-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 2.5rem;
  padding: 0;
  border: none;
  outline: none;
  background-color: var(--default-background-color-header);
  border-radius: 4px;
  margin: 2px 1rem 2px 0;
}

.header-button:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
}

.header-icon-label {
  color: #44affc;
  font-family: "CoopGroteskRegular";
  font-size: 13px;
  margin-top: 0.25rem;
}

.header-icon-label-selected {
  color: #ffffff;
}

#shoppinlist-button > img {
  margin-right: 0.125rem;
}
