.warning-container {
  position: absolute;
  top: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 83vw;
  padding: 0.5rem 1rem;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d62215;
  border-radius: 8px;
  z-index: 5;
}

.warning-text {
  width: 80%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  font-family: "CoopGroteskRegular";
  color: #003366;
}

@media screen and (min-width: 600px) {
  .warning-container {
    width: 498px;
  }
}
