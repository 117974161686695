.shoppinglist-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.shoppinglist-content {
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

.shoppinglist-items-content {
  padding: 0 1rem 1.5rem 1rem;
  background-color: var(--coop-light-blue);
}

.shoppinglist-scroll {
  overflow-y: auto;
}

.shoppinglist-noscroll {
  overflow-y: hidden;
}

.fade-overlay {
  z-index: 2;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--fade-background-color);
  width: 100vw;
  height: 100%;
}
