.figure-container {
  width: 100;
  height: auto;
  background-color: #dcf0fa;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.figure-title-container {
  width: 100%;
  height: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.figure-title {
  color: #003366;
  display: inline;
  margin-left: 1rem;
  padding-right: 3rem;
}

.figure-text {
  width: 70%;
  font-weight: 400;
  color: #003366;
  text-align: center;
}

.figure-images {
  width: 80%;
  height: auto;
  margin: 2rem 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.figure-salmon {
  margin-top: 1rem;
}

.figure-document {
  margin-bottom: 1rem;
}

.figure-bananas {
  margin-top: 1rem;
}

@media (min-width: 500px) {
  .figure-container {
    width: 25rem;
    margin: 1.5rem auto;
  }
}
