.main-menu-content {
	border-radius: 12px;
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
	background-color: white;
	cursor: pointer;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	color: #003366;
}

.menu-element-list {
	margin: 0;
	padding: 0;
}

.menu-element-wrapper {
	list-style: none;
}

.menu-element {
	min-height: 3rem;
	width: 100%;
	padding: 0;
	box-sizing: border-box;
	display: inline-flex;
	border: none;
	background-color: white;
	color: #000000;
	font-size: 16px;
	font-family: "CoopGroteskRegular";
}

.menu-element:focus {
	outline: none;
}

.menu-element-button {
	width: 100%;
	padding: 0 1rem;
	margin: 0;
	display: flex;
	align-items: center;
	border: none;
	background-color: white;
}

.menu-element-button:focus {
	border-radius: 4px;
	box-shadow: 0 0 0 2px var(--focus-color);
	padding-left: calc(1rem - 2px);
	padding-right: calc(1rem - 2px);
	margin: 2px;
	outline: none;
}

.menu-element-button:hover {
	background-color: #d3e3f2;
}

.more-options-button {
	background-color: white;
	padding: 0 1rem;
	border: none;
	outline: none;
}

.more-options-button:focus {
	border-radius: 4px;
	box-shadow: 0 0 0 2px var(--focus-color);
	padding-left: calc(1rem - 2px);
	padding-right: calc(1rem - 2px);
	margin: 2px;
	outline: none;
}

.more-options-button:hover {
	background-color: #d3e3f2;
}

.menu-new-list-button {
	font-family: "CoopGroteskRegular";
	width: 100%;
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0 1rem;
	border: none;
	background: none;
}

.menu-new-list-icon {
	width: 1.3rem;
	margin: 0 1rem 0 0;
}

.menu-new-list-button:focus {
	border-radius: 8px;
	box-shadow: 0 0 0 2px var(--focus-color);
	padding-left: calc(1rem - 2px);
	padding-right: calc(1rem - 2px);
	margin: 2px;
	outline: none;
}

.menu-new-list-button:hover {
	background-color: #d3e3f2;
	cursor: pointer;
}

.menu-element-sign-in-out {
	margin: 1.5rem 1rem 1rem 1rem;
}

.menu-button--login {
	width: 100%;
	margin-bottom: 0.5rem;
}
