.list-options-list {
  margin: 0;
  padding: 0;
}

.list-options {
  border-radius: 12px;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: #003366;
}

.list-option-element {
  display: flex;
  min-height: 3rem;
}

.menu-option-icon {
  width: 1.3rem;
  margin: 0 1rem 0 0;
}

.menu-option-button {
  border: none;
  outline: none;
  padding: 0 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background: white none;
  color: #000000;
  font-size: 16px;
  font-family: "CoopGroteskRegular";
}

.menu-option-button:focus {
  border-radius: 4px;
  box-shadow: 0 0 0 2px var(--focus-color);
  padding-left: calc(1rem - 2px);
  padding-right: calc(1rem - 2px);
  margin: 2px;
  outline: none;
}

.menu-option-button:hover {
  background-color: #d3e3f2;
}

.delete-option-button {
  border: none;
  outline: none;
  padding: 0 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background: white none;
  color: #000000;
  font-size: 16px;
  font-family: "CoopGroteskRegular";
}

.delete-option-button:focus {
  border-radius: 8px;
  box-shadow: 0 0 0 2px var(--focus-color);
  padding-left: calc(1rem - 2px);
  padding-right: calc(1rem - 2px);
  margin: 2px;
  outline: none;
}

.delete-option-button:hover {
  background-color: #ffecef;
}
