.live-window-container {
    position: relative;
    bottom: 1rem;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 1rem;
    background-color: white;
    border-radius: 16px;
    padding: 0.8rem;
    box-sizing: border-box;
    box-shadow: 0px 8px 24px rgba(0, 51, 102, 0.2);
}

@media screen and (min-width: 600px) {
    .live-window-container {
        width: 560px;
        margin: 0 auto;
    }
}
