.search-field-container {
  width: 100vw;
  background-color: var(--default-background-color-header);
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

.search-field-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.search-field {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 1rem 0.5rem 1rem;
  padding: 0 1rem;
  outline: none;
}

.search-field-focus {
  box-shadow: 0 0 0 2px var(--focus-color);
}

.search-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.search-input {
  position: relative;
  width: 100%;
  min-height: 1.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 0 auto;
  border: none;
  font-size: 16px;
  background-color: white;
  color: #003366;
}

.search-input::placeholder {
  color: #003366;
  opacity: 0.6;
}

.search-input:focus {
  outline: none;
  height: 1.5rem;
}

.search-input-label {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.search-cancel-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 0.6rem 0 0.4rem 0;
  background-color: var(--default-background-color-header);
  font-family: "CoopGroteskRegular";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
  border-radius: 4px;
}

.search-cancel-button:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
}

.search-cancel-button-expand {
  width: 3.5rem;
  margin-right: 1rem;
  padding-left: 2px;
  padding-right: 2px;
  transition: width 0.3s ease-in-out 0s, margin-right 0.3s ease-in-out 0s;
}

.search-cancel-button-minimize {
  width: 0;
  margin-right: 0rem;
  padding-left: 0;
  padding-right: 0;
  transition: width 0.3s ease-in-out 0s, margin-right 0.3s ease-in-out 0s;
}

.search-cancel-button-minimize:focus {
  box-shadow: none;
}

.search-cancel-button-text {
  display: inline-block;
  width: 2.5rem;
}

.search-add-button {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: white;
}

.suggestion-container {
  position: absolute;
  margin: 4rem 0 0 0;
  right: 0;
  left: 0;
  max-height: calc(100vh - 9rem);
  overflow-y: auto;
  z-index: 3;
}

.suggestion-list {
  margin: 0;
  padding: 0 1rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.suggested-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: #003366;
  color: #003366;
  margin: 0.25rem auto 0 auto;
  width: 100%;
  min-height: 3rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  overflow-wrap: break-word;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 17px;
}

.suggested-item:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-color);
}

.suggested-item:hover {
  background-color: #d3e3f2;
  cursor: pointer;
}

@media (max-width: 600px) {
  .suggested-item:hover {
    background-color: #ffffff;
  }
}

.suggested-item:hover {
  cursor: pointer;
}

.suggestion-name {
  display: block;
  font-size: 17px;
  text-align: left;
  width: 80%;
}

.add-suggestion-button {
  font-family: "CoopGroteskBold";
  font-size: 14px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: none;
  color: #44affc;
}

.add-suggestion-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
}

.active-suggestion {
  border: 0.12rem solid black;
}

.suggestion-header-hide {
  display: none;
}

@media screen and (min-width: 600px) {
  .search-field-wrapper {
    width: 600px;
  }
  .search-field {
    width: 600px;
    box-sizing: border-box;
    margin: 0 1rem;
  }

  .search-field-focused {
    width: 492px;
    margin: 0 1rem;
  }

  .suggestion-container {
    position: fixed;
    margin: 5rem auto 0 auto;
    width: 590px;
  }
}
