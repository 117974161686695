.collapsible__button {
  width: 100%;
  border: none;
  background-color: var(--coop-light-blue);
  padding: 0 1rem 0 0;
  overflow: hidden;
  border-radius: 4px;
  margin: 0.25rem 1rem 0 1rem;
}

.collapsible__button:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
  outline: none;
}

.collapsible__button:active {
  box-shadow: 0 0 0 2px var(--focus-color);
  outline: none;
}

.collapsible__button:hover {
  background-color: #d3e3f2;
}

@media screen and (min-width: 600px) {
  .collapsible__button:hover {
    width: 560px;
  }
  .collapsible__button:focus {
    width: 560px;
  }

  .collapsible__button:active {
    width: 560px;
  }
}

.collapsible__content {
  width: 100%;
}

.collapsible__title {
  display: flex;
  justify-content: space-between;
  margin: 0.625rem 0 0.5rem 0;
}

.collapsible__chevron {
  right: 4px;
  position: relative;
}

.collapsible__chevron--open {
  transform: rotate(180deg);
}

.collapsible__chevron--open-animate {
  animation-duration: 400ms;
  animation-name: rotate-up;
  animation-fill-mode: forwards;
}

.collapsible__chevron--closed-animate {
  animation-duration: 400ms;
  animation-name: rotate-down;
  animation-fill-mode: forwards;
}

@keyframes rotate-up {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-180deg);
  }
}

@keyframes rotate-down {
  from {
    transform: rotate(-180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}
