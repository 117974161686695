.item-container {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}

.item-drag-container {
  width: 100%;
  margin-top: 0.25rem;
}

.item-element {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  min-height: 3rem;
  margin: 0 auto;
  padding: 0;
  border-radius: 8px;
  overflow-wrap: break-word;
  box-sizing: border-box;
  z-index: 1;
  transition: background-color 0.5s ease;
}

.item-element:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
  outline: none;
}

.item-element:hover {
  cursor: pointer;
}

.item-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: rgb(0, 0, 0, 0);
  outline: none;
  border-radius: 8px;
  width: 100%;
  margin: 0;
  font-size: 17px;
  padding: 0.5rem 0 0.5rem 1rem;
}

.item-text-container:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
}

.item-text-container-checked {
  padding-right: 1rem;
}

.item-text-nodrag:hover {
  background-color: #d3e3f2;
}

.item-description-container {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  width: 100%;
}

.item-name {
  font-family: "CoopGroteskRegular";
  display: flex;
  justify-content: flex-start;
  font-size: 17px;
  line-height: 20px;
}

.item-note {
  font-family: "CoopGroteskRegular";
  display: flex;
  justify-content: flex-start;
  font-size: 13px;
  line-height: 16px;
  color: #00000061;
}

.item-note-checked {
  color: #003366;
}

.item-quantity {
  display: flex;
  justify-content: flex-end;
  width: 3rem;
  margin: 0.2rem 0 0 0;
  font-size: 17px;
}

.item-edit-button {
  display: flex;
  align-items: center;
  padding: 0 1rem 0 1rem;
  margin: 0;
  border-radius: 8px;
  border: none;
  background-color: rgb(0, 0, 0, 0);
}

.item-edit-button:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
  outline: none;
}

.item-edit-button:hover {
  background-color: #d3e3f2;
}

.checkbox {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 4px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.checkbox:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
  outline: none;
}

.item-unchecked {
  background-color: white;
  color: #003366;
}

.item-unchecked:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
}

.item-checked {
  background-color: var(--coop-light-blue);
  color: #003366;
  text-decoration-line: line-through;
}

.item-drag {
  background-color: #44affc;
}

.item-drag:hover {
  background-color: #44affc;
}

.item-drag-icon {
  background-color: #44affc;
}

.hide {
  display: none;
}

.item-button-img {
  background-color: transparent;
}

.item-button-img:hover {
  background-color: #d3e3f2;
}

@media (max-width: 600px) {
  .item-text-nodrag:hover {
    background-color: transparent;
  }
  .item-edit-button:hover {
    background-color: transparent;
  }
  .item-button-img:hover {
    background-color: transparent;
  }
}
