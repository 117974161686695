.button {
    font-family: "CoopGroteskBold";
    font-weight: 100;
    font-size: 16px;
    padding: 0 2rem;
    border-radius: 100px;
    min-height: 2.5rem;
}

.button:focus {
    box-shadow: 0 0 0 2px var(--focus-color);
    outline: none;
}

.button:hover, .button:active {
    cursor: pointer
}

.primary-button {
    border: 1px solid #003366;
    color: white;
    background-color: #003366;
}

.primary-button:hover {
    background-color: #0040cc;
    border: 1px solid #0040cc;
}

.primary-button:active {
    background-color: #0050ff;
    border: 1px solid #0050ff;
}

.secondary-button {
    border: 1px solid #003366;
    color: #003366;
    background-color: white;
}

.secondary-button:hover {
    color: #0050ff;
    border: 1px solid #0050ff;
}

.secondary-button:active {
    color: #0050ff;
    border: 2px solid #0050ff;
}

.window-close-button {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100px;
    padding: 0;
    border: none;
    outline: none;
    background-color: #003366;
}

.window-close-button:focus {
    box-shadow: 0 0 0 2px var(--focus-color);
}

.window-close-button:hover {
    cursor: pointer;
    background-color: #0040cc;
}

.window-close-button:active {
    background-color: #0050ff;
    cursor: pointer;
}

