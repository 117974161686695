.category-title {
  color: #003366;
  font-size: 17px;
  width: 100%;
  margin: 0;
}

.checked-category-title {
  font-family: "CoopGroteskRegular";
  color: #003366;
  font-size: 17px;
  width: 100%;
  margin: 0;
}
.category-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.category-content {
  width: 100%;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
}
