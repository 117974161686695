.loginhint-title {
    margin: 0 0.5rem 0.5rem 0.5rem;
    text-align: center;
    color: var(--coop-dark-blue);
}

.loginhint-text {
    margin: 0 0.5rem;
    text-align: center;
}

.loginhint-button {
    margin: 1rem auto 0.5rem auto;
}

.loginhint-close-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
