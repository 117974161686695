.edit-item-input {
  width: calc(100% - 4rem);
  min-height: 2.5rem;
  margin: 1.5rem auto 1rem auto;
  padding: 0 1rem 0 1rem;
  border: 0.5px solid #3c3c434a;
  font-size: 17px;
  font-family: "CoopGroteskRegular";
  background-color: #ffffff;
  color: #000000de;
  border-radius: 8px;
  -webkit-appearance: none;
  box-shadow: 0 0 0 2px transparent;
}

.edit-item-input:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
  outline: none;
}

.edit-note-input {
  width: calc(100% - 4rem);
  min-height: 3rem;
  margin: 1rem auto 0 auto;
  padding: 1rem 1rem 1rem 1rem;
  border: 0.5px solid #3c3c434a;
  font-family: "CoopGroteskRegular";
  font-size: 17px;
  color: #000000de;
  background-color: #ffffff;
  border-radius: 8px;
  resize: none;
  outline: none;
  -webkit-appearance: none;
  box-shadow: 0 0 0 2px transparent;
}

.edit-note-input::placeholder {
  color: #8f8f8f;
  font-family: "CoopGroteskRegular";
  font-size: 17px;
}

.edit-note-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-color);
}

.edit-dropdown {
  width: calc(100% - 2rem);
  min-height: 2.625rem;
  margin-bottom: 1rem;
  padding: 0 1rem 0 1rem;
  border: 0.5px solid #3c3c434a;
  border-radius: 8px;
  font-size: 17px;
  font-family: "CoopGroteskRegular";
  background: transparent;
  background-image: url("icons/dropdownChevron.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: 0 0 0 2px transparent;
}

.edit-dropdown:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-color);
  caret-color: #0050ff;
}

.edit-quantity-slider {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 2px 0;
}

::-webkit-scrollbar {
  display: none;
}
.edit-quantity-button {
  min-width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.3rem;
  padding: 0;
  border-radius: 50%;
  text-align: center;
  font-family: "CoopGroteskBold";
  font-weight: 100;
  font-size: 17px;
  color: #00000061;
  border: none;
  outline: none;
  background-color: transparent;
}

.edit-quantity-button-selected {
  background-color: #003366;
  color: #ffffff;
}
.edit-quantity-button-first {
  margin-left: 1rem;
}

.edit-quantity-button-last {
  margin-right: 0.5rem;
}

.edit-quantity-button:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
}

.edit-quantity-button-text {
  margin-top: 0.1rem;
}

.edit-empty-span {
  height: 2.5rem;
  min-width: 0.5rem;
}

.edit-delete-button {
  background-color: transparent;
  border: none;
  font-family: "CoopGroteskBold";
  font-weight: 100;
  font-size: 16px;
  color: #d62215;
  margin-top: -1rem;
  padding: 0.8rem 1rem;
  margin-bottom: 0.2rem;
  border-radius: 100px;
  box-shadow: 0 0 0 2px transparent;
}

.edit-delete-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-color);
}

.edit-delete-button:hover {
  color: #e67a73;
}
