.popup-header {
  text-align: center;
  margin: 2rem 1rem 0.5rem 1rem;
  color: #003366;
  max-width: 90%;
  word-wrap: break-word;
  font-size: 18px;
}

.popup-input {
  width: calc(100% - 6rem);
  font-size: 17px;
  min-height: 2.5rem;
  padding: 0 1rem;
  margin: 1rem 1rem 0.5rem 1rem;
  color: black;
  background: #ffffff;
  -webkit-appearance: none; /* Remove default input styling on ios */
  border: 0.5px solid rgba(60, 60, 67, 0.29);
  border-radius: 8px;
}

.popup-input-warning {
  width: calc(100% - 6rem);
  font-size: 17px;
  min-height: 2.5rem;
  padding: 0 1rem;
  margin: 1rem 1rem 0.5rem 1rem;
  color: black;
  background: #e6e6e6;
  -webkit-appearance: none; /* Remove default input styling on ios */
  border: 1px solid #d62215;
  border-radius: 8px;
}

.popup-input::placeholder {
  color: #003366;
  opacity: 0.4;
}

.popup-input:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
  outline: none;
}

.popup-input-warning::placeholder {
  color: #003366;
  opacity: 0.4;
}

.popup-input-warning:focus {
  outline: none;
}

.popup-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 1.5rem 0 2rem 0;
}

.popup-buttons-container-less-space-above {
  margin: 0.5rem 0 2rem 0;
}

.empty-name-warning {
  color: #d62215;
  width: calc(100% - 4rem);
}

.hide {
  visibility: hidden;
}
