.fade-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--fade-background-color);
  z-index: 4;
  animation-duration: 0.2s;
  animation-name: popup-fade-in;
  overflow: hidden;
}

.popup-animation-container {
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-end;
}

.popup-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 5rem;
  width: calc(100% - 2rem);
  min-height: 8rem;
  border-radius: 8px;
  background-color: white;

  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 600px) {
  .popup-container {
    width: 480px;
  }
}
