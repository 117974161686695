.map-feedback-container {
  width: calc(100% - 2rem);
  margin-top: 1rem;
  margin-bottom: 2rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
}

.map-feedback-header {
  color: var(--coop-dark-blue);
  font-size: 24px;
  margin: 1.5rem 0 1.5rem 0;
}

.map-feedback-sub-header {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.map-feedback-link {
  font-family: "CoopGroteskBold";
  font-weight: 100;
  font-size: 16px;
  color: #ffffff;
  margin: 1.5rem 0 1.5rem 0;
  border: 1px solid #003366;
  padding: 1rem 1.5rem 1rem 1.5rem;
  background-color: var(--coop-dark-blue);
  border-radius: 100px;
  text-decoration: none; /* no underline */
}

.map-feedback-link:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
  outline: none;
}

.map-feedback-link:hover {
  background-color: #0040cc;
  border: 1px solid #0040cc;
  cursor: pointer;
}

.map-feedback-link:active {
  background-color: #0050ff;
  border: 1px solid #0050ff;
  cursor: pointer;
}
