.recommended-container {
  right: 0;
  left: 0;
  background-color: #efefe9;
  margin-top: 1.5rem;
}

.recommended-content {
  padding: 1rem 1rem 5rem 1rem;
}

.recommended-title {
  margin: 0;
  margin-bottom: 0.5625rem;
  font-family: "CoopGroteskRegular";
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #003366;
}

.recommended-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.recommended-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: #003366;
  color: #003366;
  margin: 0.25rem auto 0 auto;
  width: 100%;
  min-height: 3rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  overflow-wrap: break-word;
  border-radius: 8px;
  box-sizing: border-box;
}

.recommended-item:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-color);
}

.recommended-item:hover {
  background-color: #d3e3f2;
  cursor: pointer;
}

.recommended-name {
  display: block;
  font-size: 17px;
}

.recommended-add-item-text {
  font-family: "CoopGroteskBold";
  font-size: 14px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: none;
  color: #44affc;
}

@media screen and (min-width: 600px) {
  .recommended-container {
    width: 100vw;
    margin: 1.5rem auto 0 auto;
  }
  .recommended-content {
    width: 560px;
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .recommended-item:hover {
    background-color: #ffffff;
  }
}
