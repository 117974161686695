.uncategorized-list-container {
  position: relative;
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.uncategorized-list-container-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 600px) {
  .uncategorized-list-container {
    margin: 0 auto;
    width: 560px;
  }
  .uncategorized-list-container-header {
    margin: 0 auto;
    width: 560px;
  }
}

.uncategorized-list-info-text {
  font-family: "CoopGroteskRegular";
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #003366;
  list-style-type: none;
  margin: 1rem 0 0 0;
}

.uncategorized-list-item-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 1.5rem;
  background-color: #003366;
  color: white;
  border-radius: 12px;
  font-size: 13px;
}
