.eror-page-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.error-header {
  width: 100vw;
  height: 10vh;
  background: #003366;
}

.error-container {
  width: 100vw;
  height: 90vh;
  background-color: #dcf0fa;
  text-align: center;
}

.error-container-icon {
  margin: 8vh 0 5vh;
}

.error-container-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  margin: 1rem;
  font-family: "CoopGroteskBold";
  color: #003366;
}

.error-container-sub-title {
  font-size: 18px;
  line-height: 20px;
  margin: 0.5rem;
  color: #003366;
}
.error-container-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  font-family: "CoopGroteskRegular";
  color: #003366;
}

.error-button {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 2rem;
  width: 8rem;
  height: 2rem;
  border: none;
  outline: none;
  border-radius: 100px;
  background-color: #003366;
  color: white;
  text-align: center;
}

.error-button:hover {
  cursor: pointer;
}
