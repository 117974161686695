.map-shop-dropdown {
  width: calc(100% - 3rem);
  min-height: 2.625rem;
  margin: 1rem 0 1rem 0;
  padding: 0 1rem 0 1rem;
  border: 0.5px solid #3c3c434a;
  border-radius: 8px;
  font-size: 17px;
  font-family: "CoopGroteskRegular";
  background: #ffffff;
  background-image: url("icons/dropdownChevron.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: 0 0 0 2px transparent;
}

.map-shop-dropdown:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-color);
  caret-color: #0050ff;
}

.map-coop-store-img {
  width: calc(100% - 3rem);
  padding-bottom: 1rem;
}
