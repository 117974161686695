.title {
	font-family: "CoopGroteskBold";
	font-weight: 100;
	font-size: 24px;
	line-height: 28px;
	padding-top: 5px;
	text-align: center;
}

.normal-text {
	font-family: "CoopGroteskRegular";
	font-size: 17px;
	line-height: 20px;
	letter-spacing: 0em;
}
