.delete-items-button {
  width: 8rem;
  min-height: 2.5rem;
  border-radius: 100px;
  margin: 1rem auto 0 auto;
  color: #003366;
  font-size: 12px;
  font-weight: 700;
  border: 1px solid #003366;
  background-color: var(--coop-light-blue);
}

.delete-items-button:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
  outline: none;
}

.delete-items-button:hover {
  color: #0050ff;
  border: 1px solid #0050ff;
}
