.info-popup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  min-height: 3rem;
  margin: 0 1rem;
  padding: 0.8rem 1rem 0.8rem 1rem;
  border-radius: 8px;
  overflow-wrap: break-word;
  box-sizing: border-box;
  background-color: #003366;
  filter: drop-shadow(0px 8px 24px rgba(0, 51, 102, 0.2));
}

.info-popup-container {
  position: absolute;
  width: 100%;
  bottom: 5rem;
  z-index: 3;
}

.info-popup-message {
  color: white;
  font-size: 15px;
  flex-shrink: 2;
  width: 100%;
  word-break: break-word;
}
.info-popup-button {
  height: 1.5rem;
  padding: 0;
  border: none;
  outline: none;
  background-color: #003366;
  margin: 0 0 0 1rem;
  flex-shrink: 1;
}

.info-popup-button:focus {
  box-shadow: 0 0 0 2px var(--focus-color);
  outline: none;
}

.info-popup-button-text {
  font-family: "CoopGroteskBold";
  font-weight: 100;
  color: #44affc;
  font-size: 15px;
}

@media screen and (min-width: 600px) {
  .info-popup {
    width: 600px;
    margin: 0 auto;
  }
}
