.add-homescreen-hide {
  display: none !important;
}

.add-homescreen-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.add-homescreen-button {
  margin: 1rem auto;
}

.add-homescreen-content-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.add-homescreen-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 1rem;
}

.add-homescreen-text {
  font-family: "CoopGroteskBold";
  font-size: 14px;
  color: black;
  line-height: 16px;
  display: block;
  margin: 0;
  padding: 0.2rem;
  border-radius: 4px;
}

.add-homescreen-text:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-color);
}

.add-homescreen-subtext {
  font-family: "CoopGroteskRegular";
  font-size: 14px;
  color: #003366;
  line-height: 20px;
  display: block;
}
