.map-header {
  width: 100%;
  background-color: var(--coop-dark-blue);
}

.map-coop-store-type-container {
  width: 100%;
  min-height: 3.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  background-color: var(--coop-dark-blue);
}

.map-coop-store-type-button {
  margin: 0;
  padding: 0.75rem 0.21875rem 0.75rem 0.21875rem;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
}

.map-coop-store-type-button-underline {
  border-bottom: 2px solid #44affc;
  padding-bottom: 0.625rem;
}

.map-coop-store-type-button:focus {
  box-shadow: 0 0 0 2px var(--focus-color) inset;
}

.map-coop-store-type-img {
  min-width: 6rem;
  height: 2rem;
}

@media screen and (min-width: 600px) {
  .map-coop-store-type-container {
    width: 600px;
    margin: 0 auto;
  }
}
