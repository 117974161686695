.map-page-container {
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1;
  overflow-y: scroll;
}

.map-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (min-width: 600px) {
  .map-container {
    width: 600px;
    margin: 0 auto;
  }
}

.map-container-illustration {
  background-image: url("icons/illustrasjoner.svg");
}
